.bg-color {
   /* background-color: #3b064d;*/
}

/*.Home .img-responsive {
    margin: 0 auto;
}*/

.box-logo {
    width: 220px;
    height: 80px;
    margin: 0 !important;
}

.blurb{
    color: #1abc9c;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}

.storeicon {
    width: 300px!important;
    height: auto!important;
    text-align: center;
    margin: 0 auto!important;
}

.boxapopa {
    color: #ba2ecc;
}

.fungame {
    color: #0496ff;
}

.kids {
    color: #ffbc42;
}

.blurbCard {
    height: 420px;
    background-color: #3b064d !important;
   border: 0 !important;
    padding: 30px !important;
}

.boxImage {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.blurbRow {

}

.screenshots {

}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.screens1{
    padding: 30px !important;
    padding-top: 30px!important;
    background-color: #1abc9c;
}

.screens2{
    padding: 30px !important;
    padding-top: 20px!important;
    background-color: #3b064d;
}

.screens3{
    padding: 30px !important;
    background-color: #0496ff;
}

.screens4{
    padding: 30px !important;
    background-color: #ffbc42;
}

.screens5{
    padding: 30px !important;
    padding-top: 20px!important;
    background-color: #3b064d;
}

.screens6{
    padding: 30px !important;
    background-color: #ba2ecc;
}

.screens8{
    padding: 30px !important;
    padding-top: 20px!important;
    background-color: #1abc9c
}

.screens7{
    padding: 30px !important;
    padding-top: 20px!important;
    background-color: #3b064d;
}

.screens{
    background-color: #3b064d;
    padding: 20px;
    max-height: 550px!important;
}

.grid1{
    background-color: #61dafb;
}

.links {
    color: #0496ff;
}

.social {
    max-width: 50px;
    max-height: 50px;
    padding:5px;
}

.divmor {
    background-color: #61dafb;
}

.appBadge{
    background: transparent;
    border: 0;
}